import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/mange',
    name: 'Manage',
    component: () => import('../views/Manage.vue'),
    children:[
      {path: 'home', name:'Home',component: () => import('../views/Home.vue'),},
      {path: 'userinfo', name:'Userinfo',component: () => import('../views/Userinfo.vue'),},
      {path: 'zyinfo', name:'Zyinfo',component: () => import('../views/Zyinfo.vue'),},
      {path: 'ywk', name:'Ywk',component: () => import('../views/Ywk.vue'),},
      {path: 'myywk', name:'MyYwk',component: () => import('../views/MyYwk.vue'),},
      {path: 'role', name:'Role',component: () => import('../views/Role.vue'),},
      {path: 'rolebutton', name:'Rolebutton',component: () => import('../views/Rolebutton.vue'),},
      {path: 'sfjl', name:'Sfjl',component: () => import('../views/Sfjl.vue'),},
      {path: 'Sfjlwsf', name:'Sfjlwsf',component: () => import('../views/Sfjlwsf.vue'),},
      {path: 'sfjladd', name:'Sfjl',component: () => import('../views/SfjlAdd.vue'),},
      {path: 'sfjltj', name:'SfjlTj',component: () => import('../views/Sfjltj.vue'),},
      {path: 'sfjlbar', name:'Sfjlbar',component: () => import('../views/Sfjlbar.vue'),},
      {path: 'hetong', name:'Hetong',component: () => import('../views/Hetong.vue'),},
      {path: 'kpxx', name:'Kpxx',component: () => import('../views/Kpxx.vue'),},
      {path: 'hetongadd', name:'Hetongadd',component: () => import('../views/HetongAdd.vue'),},
      {path: 'test', name:'test',component: () => import('../views/Test.vue'),},
      {path: 'sztj', name:'sztj',component: () => import('../views/Sztj.vue'),},
      {path: 'test1', name:'test',component: () => import('../views/Test1.vue'),},
      {path: 'jixiao', name:'jixiao',component: () => import('../views/Kaoqin.vue'),},
      {path: 'rwlistadd', name:'Rwlistadd',component: () => import('../views/RwlistAdd.vue'),},
      {path: 'kfxx', name:'kfxx',component: () => import('../views/Kfxx.vue'),},
      {path: 'kaoqin', name:'Kaoqin',component: () => import('../views/Kaoqin.vue'),},
      {path: 'kaoping', name:'Kaoping',component: () => import('../views/Kaoping.vue'),},
      {path: 'ywkadd', name:'ywkadd',component: () => import('../views/YwkAdd.vue'),},
      {path: 'kpxxadd', name:'kpxxadd',component: () => import('../views/KpxxAdd.vue'),},
      {path: 'rwlist', name:'Rwlist',component: () => import('../views/Rwlist.vue'),},
      {path: 'qylx', name:'Qylx',component: () => import('../views/Qylx.vue'),},
      {path: 'sflx', name:'Sflx',component: () => import('../views/Sflx.vue'),},
      {path: 'sydw', name:'Sydw',component: () => import('../views/Sydw.vue'),},
      {path: 'ywkbz', name:'Ywkbz',component: () => import('../views/Ywkbz.vue'),},
      {path: 'wzxin', name:'Wzxin',component: () => import('../views/Wzxin.vue'),},
      {path: 'wzxinadd', name:'Wzxinadd',component: () => import('../views/WzxinAdd.vue'),},
      {path: 'download', name:'Download',component: () => import('../views/Download.vue'),},
      {path: 'czxt', name:'Czxt',component: () => import('../views/Czxt.vue'),},
      {path: 'link', name:'Link',component: () => import('../views/Link.vue'),},
      {path: 'rjbb', name:'Rjbb',component: () => import('../views/Rjbb.vue'),},
      {path: 'rjbbh', name:'Rjbbh',component: () => import('../views/Rjbbh.vue'),},
      {path: 'rjmc', name:'Rjmc',component: () => import('../views/Rjmc.vue'),},
      {path: 'hjzjj', name:'Hjzjj',component: () => import('../views/Hjzjj.vue'),},
      {path: 'qylb', name:'Qylb',component: () => import('../views/Qylb.vue'),},
      {path: 'rckh', name:'Rckh',component: () => import('../views/Rckh.vue'),},
      {path: 'jxkp', name:'Jxkp',component: () => import('../views/Jxkp.vue'),},
      {path: 'jxkplc', name:'Jxkplc',component: () => import('../views/Jxkplc.vue'),},
      {path: 'jxkpadd', name:'JxkpAdd',component: () => import('../views/JxkpAdd.vue'),},
      {path: 'jxkphz', name:'Jxkphz',component: () => import('../views/Jxkphz.vue'),},
      {path: 'sjk', name:'Sjk',component: () => import('../views/Sjk.vue'),},
      {path: 'dwxx', name:'Dwxx',component: () => import('../views/Dwxx.vue'),},
      {path: 'other', name:'Other',component: () => import('../views/Other.vue'),},
      {path: 'password',name: 'Password',component: () => import('../views/Password')},
      {path: 'gzr',name: 'Gzr',component: () => import('../views/Gzr')},
      {path: 'zclx',name: 'Zclx',component: () => import('../views/Zclx')},
      {path: 'crklx',name: 'Crklx',component: () => import('../views/Crklx')},
      {path: 'crkjladd',name: 'CrkjlAdd',component: () => import('../views/CrkjlAdd')},
      {path: 'fyml',name: 'Fyml',component: () => import('../views/Fyml')},
      {path: 'fymlmy',name: 'FymlMy',component: () => import('../views/FymlMy')},
      {path: 'fynr',name: 'Fynr',component: () => import('../views/Fynr')},
      {path: 'crk',name: 'Crk',component: () => import('../views/Crk')},
      {path: 'crktz',name: 'Crktz',component: () => import('../views/Crktz')},
      {path: 'qcye',name: 'Qcye',component: () => import('../views/Qcye')},
      {path: 'yeb',name: 'Yeb',component: () => import('../views/Yeb')},
      {path: 'yhrjz',name: 'Yhrjz',component: () => import('../views/Yhrjz')},
    ]
  }

  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})





export default router
